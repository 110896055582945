<template>
    <div id="container" class="md-layout">
        <h1 class="md-layout-item md-size-80 md-small-size-90">
            FAQs
        </h1>

        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-content>
                Bisher noch keine häufigen Fragen.
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
export default {
    name: 'All_FAQ'
}
</script>

<style lang="scss" scoped>
#container {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
    align-content: center;
    text-align: center;
}

.md-card {
    text-align: center;
    width: 980px;
    max-width: 90%;
    margin: 10px auto 10px;
    display: inline-block;
    vertical-align: top;
}

h1 {
    text-align: center;
    margin: 10px auto 10px;
}
</style>
